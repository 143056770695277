import React, { useEffect, useRef } from 'react';
import { gsap, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as S from './styled';

const WebsiteProjectView = ({
  description,
  featuredimage,
  technology,
  design,
  typhography,
  colors,
  link,
}) => {
  const { t } = useTranslation();
  const image = getImage(featuredimage.src);
  const projectViewItems = useRef([]);
  projectViewItems.current = [];
  const addToRefs = (el) => {
    if (el && !projectViewItems.current.includes(el)) {
      projectViewItems.current.push(el);
    }
  };
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(projectViewItems.current, { autoAlpha: 0, y: 70 });
    ScrollTrigger.batch(projectViewItems.current, {
      start: 'top bottom-=100px',
      // markers: true,
      onEnter: (batch) =>
        gsap.to(batch, {
          duration: 1.5,
          autoAlpha: 1,
          y: 0,
          ease: Power4.easeOut,
          stagger: 0.3,
          delay: 0.3,
        }),
    });
  }, []);
  return (
    <S.Wrapper id="WebsiteProjectView">
      <S.Container>
        <GatsbyImage
          ref={addToRefs}
          image={image}
          alt={featuredimage.alt}
          objectFit="contain"
          style={{
            width: '100%',
          }}
        />
        <S.ContentContainer ref={addToRefs}>
          <S.Title>{t('description')}</S.Title>
          <S.Description>{description}</S.Description>
        </S.ContentContainer>
        {technology && (
          <S.ContentContainer ref={addToRefs}>
            <S.Title>{t('technology')}</S.Title>
            <S.Description>
              {technology.map((tech) => (
                <S.Item key={tech}>{tech}</S.Item>
              ))}
            </S.Description>
          </S.ContentContainer>
        )}
        {design && (
          <S.ContentContainer ref={addToRefs}>
            <S.Title>{t('graphics')}</S.Title>
            <S.Description>
              {design.map((designer) => (
                <S.Item key={designer}>{designer}</S.Item>
              ))}
            </S.Description>
          </S.ContentContainer>
        )}
        {typhography && (
          <S.ContentContainer ref={addToRefs}>
            <S.Title>{t('typhography')}</S.Title>
            <S.Description>
              {typhography.map((font) => (
                <S.Item key={font}>{font}</S.Item>
              ))}
            </S.Description>
          </S.ContentContainer>
        )}
        {colors && (
          <S.ContentContainer ref={addToRefs}>
            <S.Title>{t('colors')}</S.Title>
            <S.Description>
              {colors.map((item) => (
                <S.Color key={item} color={item}>
                  {item}
                </S.Color>
              ))}
            </S.Description>
          </S.ContentContainer>
        )}
        {link && (
          <S.ContentContainer ref={addToRefs}>
            <S.Title>{t('resource')}</S.Title>
            <S.Description>
              <S.SiteAnchor
                href={link}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                {link}
              </S.SiteAnchor>
            </S.Description>
          </S.ContentContainer>
        )}
      </S.Container>
    </S.Wrapper>
  );
};

export default WebsiteProjectView;
