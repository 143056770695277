import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

import TitlePage from '../../components/TitlePage';
import WebsiteProjectView from '../../components/WebsiteProjectView';
// import Anchorlink from '../components/Anchorlink';
import * as S from '../styled';
import WrapperPage from '../../components/WrapperPage';
import WrapperSection from '../../components/WrapperSection';
import PageImage from '../../components/PageImage';
import Breadcrumbs from '../../components/Breadcrumbs';

const ProjectDevTemplate = ({
  description,
  heading,
  subtitle,
  featuredimage,
  technology,
  design,
  typhography,
  colors,
  link,
  slug,
  pageContext,
}) => (
  <WrapperPage>
    <PageImage>
      <StaticImage
        src="../../assets/images/draw.jpg"
        alt="Blog"
        style={{ width: '100%', height: '100%', opacity: 0.2 }}
        layout="fullWidth"
        objectPosition="center 65%"
        quality={85}
        imgStyle={{ filter: 'grayscale()' }}
      />
    </PageImage>
    <WrapperSection>
      <Breadcrumbs
        category="Strony Intenetowe"
        categoryHref="strony-internetowe"
        post={heading}
        postHref={`strony-internetowe${slug}`}
      />
      <S.PostContainer>
        <TitlePage text={heading} subtitle={subtitle} />
        <WebsiteProjectView
          description={description}
          featuredimage={featuredimage}
          technology={technology}
          design={design}
          typhography={typhography}
          colors={colors}
          link={link}
          pageContext={pageContext}
        />
      </S.PostContainer>
    </WrapperSection>
  </WrapperPage>
);

ProjectDevTemplate.propTypes = {
  description: PropTypes.string.isRequired,
};

export default ProjectDevTemplate;
