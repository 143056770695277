import styled from 'styled-components';
import media from 'styled-media-query';

export const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0 10px;
  .post-body {
    p {
      margin: 10px 0;
    }
    h2,
    h3,
    h4 {
      line-height: 32px;
      font-size: 26px;
      font-weight: 700;
      margin: 15px 0;
      color: var(--firstthirdy-color);
    }
    a {
      color: var(--thirdy-color);
      text-decoration: none;
      transition: color 0.5s 0.2s ease-in-out;
      &:hover {
        color: var(--primary-color);
      }
    }
  }
  ${media.greaterThan('large')`
    max-width:1170px;
  `}
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 25px auto;
`;

export const TagsTitle = styled.h1`
  font-size: 26px;
  margin: 0 0 30px;
  font-weight: 700;
  color: var(--primary-color);
  position: relative;
`;

export const TagsTitleOnList = styled.h2`
  font-size: 20px;
  font-weight: 400;
  color: var(--primary-color);
  margin: 0 5px 20px;
`;

export const TagsContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 20px;
`;

export const StyledContainer = styled.div`
  display: flex;
`;

export const StyledTag = styled.div`
  margin-bottom: 3rem;
`;

export const TagsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: inherit;
  margin: 0 25px;
  padding: 0;
`;

export const TagsListOnPost = styled(TagsList)`
  list-style: none;
  margin: 0;
`;

export const ListItem = styled.li`
  margin-right: 15px;
`;
