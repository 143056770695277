import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import ProjectDevTemplate from './projectDevTemplate';

const ProjectTemplate = ({ data: { project }, pageContext }) => (
  <Layout>
    <ProjectDevTemplate
      title={project.frontmatter.title}
      subtitle={project.frontmatter.subtitle}
      heading={project.frontmatter.heading}
      description={project.frontmatter.description}
      featuredimage={project.frontmatter.featuredimage}
      technology={project.frontmatter.technology}
      design={project.frontmatter.design}
      typhography={project.frontmatter.typhography}
      colors={project.frontmatter.colors}
      link={project.frontmatter.link}
      slug={pageContext.slug}
      pageContext={pageContext}
    />
  </Layout>
);

export default ProjectTemplate;

export const Head = ({ data: { project } }) => (
  <SEO
    title={`${project.frontmatter.heading} - Giedeé Informatyka`}
    description={`${project.frontmatter.description}`}
  />
);

export const query = graphql`
  query ($locale: String!, $slug: String!) {
    project: mdx(
      fields: { slug: { eq: $slug }, locale: { eq: $locale } }
    ) {
      id
      fields {
        slug
        locale
      }
      frontmatter {
        description
        title
        heading
        typhography
        templateKey
        technology
        subtitle
        design
        colors
        link
        date(formatString: "DD MMMM YYYY", locale: $locale)
        featuredimage {
          alt
          src {
            childImageSharp {
              gatsbyImageData(
                width: 1170
                quality: 85
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        # galleryImages {
        #   alt
        #   src {
        #     childrenImageSharp {
        #       gatsbyImageData(
        #         width: 800
        #         quality: 80
        #         layout: CONSTRAINED
        #         placeholder: BLURRED
        #         formats: [AUTO, WEBP]
        #       )
        #     }
        #   }
        # }
      }
    }
  }
`;
