import React, { useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import gsap, { Power1 } from 'gsap';
import { useTranslation } from 'react-i18next';

import Anchorlink from '../Anchorlink';

import * as S from './styled';

const Breadcrumbs = ({ category, categoryHref, post, postHref }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });

  let bread = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      paused: true,
      defaults: { duration: 0.5, ease: Power1.easeOut },
    });
    gsap.set(bread, { alpha: 0 });
    tl.to(bread, {
      alpha: 1,
      duration: 1.2,
      delay: 0.8,
    }).play();
  }, []);

  return (
    <S.StyledWrapper
      id="breadcrumbs"
      ref={(el) => {
        bread = el;
      }}
    >
      <S.Container>
        <S.MenuList>
          <S.ListItem>
            <Anchorlink ariaLabel={`breadcrumbs ${t('home')}`} to="/">
              {t('home')}
            </Anchorlink>
          </S.ListItem>
          <S.ListItem>
            <Anchorlink
              ariaLabel={`breadcrumbs ${t(`${category}`)}`}
              to={`/${categoryHref}`}
            >
              {t(`${category}`)}
            </Anchorlink>
          </S.ListItem>
          <S.ListItem>
            <Anchorlink
              ariaLabel={`breadcrumbs ${t(`${post}`)}`}
              to={`/${postHref}`}
            >
              {t(`${post}`)}
            </Anchorlink>
          </S.ListItem>
        </S.MenuList>
      </S.Container>
      <S.BackLink
        ariaLabel={`breadcrumbs ${t('back')}`}
        onClick={() => navigate(-1)}
      >
        {t('back')}
      </S.BackLink>
    </S.StyledWrapper>
  );
};

export default Breadcrumbs;
