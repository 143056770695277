import styled from 'styled-components';
// import media from 'styled-media-query';

export const Wrapper = styled.section`
  display: flex;
  position: relative;
  z-index: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const Title = styled.h2`
  line-height: 2.5rem;
  font-family: var(--font-open);
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-light);
  margin: 20px 0;
  position: relative;
  &:after {
    content: '';
    display: inline-block;
    width: 30%;
    height: 1px;
    background: var(--gray);
    position: absolute;
    left: 0;
    margin: 0 auto;
    bottom: -7px;
    opacity: 1;
    transition: 0.3s ease-in-out;
  }
`;

export const Description = styled.div`
  line-height: 2rem;
  font-size: 1.6rem;
  font-family: var(--font-open);
  font-weight: normal;
  color: var(--text-light);
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
`;

export const SiteAnchor = styled.a`
  text-decoration: none;
  line-height: 2rem;
  font-size: 1.6rem;
  font-family: var(--font-open);
  font-weight: normal;
  color: var(--primary-color);
  transition: color 0.5s 0.2s ease-in-out;
  &:hover,
  &:active {
    color: var(--thirdy-color);
  }
`;

export const Item = styled.div`
  margin-right: 10px;
`;

export const Color = styled.div`
  margin-right: 10px;
  color: ${({ color }) => color};
  font-size: 1.3rem;
  box-shadow: 2px 2px var(--gray-dark);
`;
