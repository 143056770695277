import styled from 'styled-components';
import media from 'styled-media-query';

export const StyledWrapper = styled.div`
  color: var(--primary-color);
  background-color: var(--secondary-color);
  width: 100%;
  max-width: 1170px;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  ${media.greaterThan('medium')`
    justify-content: space-between;

  `}
`;

export const Container = styled.div`
  justify-content: center;
  display: none;
  padding: 0 5px;
  text-align: center;
  font-size: 16px;
  ${media.greaterThan('medium')`
     display: flex;
  `}
`;

export const MenuList = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  margin-right: 7px;
  margin-bottom: 10px;
  font-size: 16px;
  line-break: none;
  &:after {
    content: '>';
    font-size: 16px;
    color: var(--thirdy-color);
    margin-left: 7px;
  }
  &:last-child {
    &:after {
      content: '';
    }
  }
`;

export const BackLink = styled.a`
  color: var(--link-color);
  text-decoration: none;
  :hover {
    color: var(--link-color-hover);
  }
`;
